<template>
    <div class="datePickerGroup" v-click-outside="hideCalender">
        
        <button type="button" class="default small round reverse" @click="switchCalendar">{{ dateBeautify }}</button>

        <div class="datePicker" v-show="show">

            <div class="header">
                <button @click="prevMonth"><i class="fas fa-caret-left"></i></button>
                <button>{{ currentMonth }} {{ currentYear }}</button>
                <button @click="nextMonth"><i class="fas fa-caret-right"></i></button>
            </div> 

            <div class="daysPicker">
                <div class="daysGroup">
                    <div class="day name" :key="key" v-for="(item, key) of days">
                        <div class="buttonHolder">
                            <button class="_btn" >{{ item }}</button>
                        </div>
                    </div>
                </div>

                <div class="daysGroup">
                    <div class="day" :key="key" v-for="(item, key) of monthDays">
                        <div class="buttonHolder">
                            <button class="_btn" @click="selectDate(item.date)" :class="{ 'active' : item.date === selectedDate, 'current' : item.date === currentDate, 'subtle' : item.subtle }">{{ item.day }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import moment from 'moment'

    export default {
        props: ['modelValue'],
        data() {
            return {
                date: this.modelValue ? moment(this.modelValue, "MM/DD/YYYY") : moment(),
                year: null,
                month: null,
                currentDate: moment().format("MM/DD/YYYY"),
                show: false,
                days: ['','Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
            }
        },
        methods: {
            switchCalendar() {
                this.show = !this.show;
            },
            hideCalender() {
                this.show = false;
            },
            selectDate(item) {
                let date = moment(item, "MM/DD/YYYY");
                this.date = date;
                this.hideCalender();
            },
            prevMonth() {
                let date = this.currentShowDate.clone();
                date.subtract(1, 'months');
                
                this.year = date.year();
                this.month = date.month();
            },
            nextMonth() {
                let date = this.currentShowDate.clone();
                date.add(1, 'months');
                
                this.year = date.year();
                this.month = date.month();
            }
        },
        computed: {
            dateBeautify() {
                return this.date.format("ddd, DD MMM, YYYY");
            },
            currentShowDate() {
                let date = moment();
                date.year(this.year);
                date.month(this.month);

                return date;
            },
            currentSelectedDate() {
                return this.date;
            },
            currentMonth() {
                return this.currentShowDate.format("MMMM");
            },
            currentYear() {
                return this.currentShowDate.format("YYYY");
            },
            selectedDate() {
                return this.currentSelectedDate.format("MM/DD/YYYY");
            },
            monthDays() {
                let date = moment();
                date.year(this.year);
                date.month(this.month);

                let numberOfDays = date.daysInMonth();
                let days = [];

                for(let i = 1; i <= numberOfDays; i++) {
                    date.date(i);
                    days.push({
                        day: i,
                        weekDay: date.isoWeekday(),
                        weekNum: date.isoWeek(),
                        date: date.format("MM/DD/YYYY")
                    });
                }

                // get the first day
                let firstDay = days[0];
                date = moment(firstDay.date, "MM/DD/YYYY");

                for(let i = 1; i < firstDay.weekDay; i++) {
                    date.subtract(1, 'days');
                    days.unshift({
                        day: date.date(),
                        weekDay: date.isoWeekday(),
                        weekNum: date.isoWeek(),
                        date: date.format("MM/DD/YYYY"),
                        subtle: true
                    });
                }

                // get the last day
                let lastDay = days[days.length - 1];
                date = moment(lastDay.date, "MM/DD/YYYY");

                for(let i = lastDay.weekDay; i < 7; i++) {
                    date.add(1, 'days');
                    days.push({
                        day: date.date(),
                        weekDay: date.isoWeekday(),
                        weekNum: date.isoWeek(),
                        date: date.format("MM/DD/YYYY"),
                        subtle: true
                    });
                }

                for(let i = 0; i < days.length; i++) {
                    if(days[i].weekDay == 1) {
                        days.splice(i, 0, {
                            day: "W" + days[i].weekNum,
                            disabled: true
                        });
                        i += 2;
                    }
                }

                return days;
            }
        },
        mounted() {
            let date = this.date;
            this.year = this.date.year();
            this.month = this.date.month();

            this.$emit('update:modelValue', this.selectedDate);
        },
        watch: {
            modelValue() {
                this.$emit('update:modelValue', this.selectedDate);
            },
            selectedDate() {
                this.$emit('update:modelValue', this.selectedDate);
            }
        }
    }
</script>

<style lang="scss" scoped>
.datePickerGroup {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.datePicker {
    position: absolute;
    top: 100%;
    right: 0;
    transform: translate(0, 20px);
    width: 100%;
    width: 340px;
    z-index: 9999;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
}

.daysPicker {
    padding: 10px;
}

.header {
    display: grid;
    grid-template-columns: 60px minmax(0, 1fr) 60px;
    height: 60px;
    border-bottom: 1px solid $borderColor;
    button {
        border: 0;
        background: transparent;
    }
}

.daysGroup {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    row-gap: 5px;
    column-gap: 5px;
    .day {
        text-align: center;
        .buttonHolder {
            width: 100%;
            padding-top: 100%;
            position: relative;
        }
        ._btn {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
            background: transparent;
            border-radius: 50%;
            padding: 0;
            &.subtle {
                color: #ccc;
            }
            &:hover {
                border: 1px solid #ccc;
            }
            &.active {
                border: 1px solid blue;
                color: blue;
                font-weight: bold;
            }
            &:disabled {
                background: $buttonColor2;
                font-size: 10px;
                cursor: auto;
                color: $buttonColor;
                &:hover {
                    border: 0;
                }
            }
        }
        &.name {
            ._btn {
                cursor: default;
                font-size: 10px;
                &:hover {
                    border: 0;
                }
            }
        }
    }
}

</style>
<template>
    <div class="newInvoiceGroup">

        
        <div class="invoiceUrlGroup">
            <Input type="text" :error="errors.password" name="Invoice URL" :value="invoiceURL" :disabled="true"/>
            <button @click="copyTextToClipboard(invoiceURL)" class="default medium">Copy link</button>
        </div>


        <div class="invoiceDateSelector">

            <div class="input">
                <div class="_header">
                    <label><h4>Invoice date</h4></label>
                    <span class="_error">{{ errors.date }}</span>
                </div>
                <DatePicker2 v-model="newInvoice.date" style="justify-content: flex-start"/>
            </div>

            <div class="input">
                <div class="_header">
                    <label><h4>Due date</h4></label>
                    <span class="_error">{{ errors.dueDate }}</span>
                </div>
                <DatePicker2 v-model="newInvoice.dueDate" style="justify-content: flex-start"/>
            </div>
            
        </div>

        <div class="input">
            <div class="_header">
                <label><h4>Carrier</h4></label>
                <span class="_error">{{ errors.carrier }}</span>
            </div>
            <div><button class="default reverse small round">{{ carriersObj[newInvoice.carrier].name }}</button></div>
        </div>

        <div class="input">
            <div class="_header">
                <label><h4>Periods</h4></label>
                <span class="_error"></span>
            </div>
            <div>{{ newInvoice.periods.join(", ") }}</div>
        </div>

        <Details v-model="newInvoice" :errors="errors" @update="$emit('update')"/>
        
        <div class="input">
            <div class="_header">
                <label><h4>Cancel an invoice</h4></label>
                <span class="_error"></span>
            </div>
            <div style="display: flex" v-if="newInvoice.status != 'cancelled'"><a href="" class="default medium round" @click.prevent="cancelInvoice">Cancel invoice</a></div>
            <div v-else>This invoice has been cancelled.</div>
        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

import Item from './Item.vue'
import MultiWeekSelector from '../../components/MultiWeekSelector.vue'
import WeekPicker from '../../components/WeekPicker.vue'
import Details from './Details.vue'


    export default {
        props: ['modelValue', 'errors'],
        components: {
            Item,
            MultiWeekSelector,
            WeekPicker,
            Details
        },
        data() {
            return {
                newInvoice: this.modelValue,
                statuses: [
                    { name: 'Awaiting payment', value: 'pending' },
                    { name: 'Paid', value: 'paid' },
                    { name: 'Cancelled', value: 'cancelled' }
                ]
            }
        },
        computed: {
            ...mapGetters(['carriers']),
            invoicePeriod() {
                return this.newInvoice ? this.newInvoice.periods : [];
            },
            invoiceCarrier() {
                return this.newInvoice ? this.newInvoice.carrier : '';
            },
            carriersObj() {
                let carriers = {};
                this.carriers.map(item => {
                    carriers[item._id] = carriers[item._id] || item;
                });
                return carriers;
            },
            invoiceURL() {
                return window.location.origin + "/invoice/" + this.newInvoice._id;
            }
        },
        mounted() {

        },
        methods: {
            cancelInvoice() {
                this.ajax('HandleCancelInvoice', {
                    url: `/invoices/CancelInvoice`,
                    method: 'PUT',
                    data: {
                        _id: this.newInvoice._id
                    }
                }, (err, body) => {
                    this.$emit('update');
                });
            },
            changeStatusTo(status) {
                this.newInvoice.status = status;
            }
        },
        watch: {
            modelValue: {
                deep: true,
                handler() {
                    this.newInvoice = this.modelValue;
                }
            },
            newInvoice: {
                deep: true,
                handler() {
                    this.$emit("update:modelValue", this.newInvoice);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

.invoiceUrlGroup {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    column-gap: 20px;
    align-items: end;
}

.statusSelection {
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
}

.invoicesItems {
    display: grid;
    row-gap: 10px;
    .item {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr)) 40px;
        column-gap: 5px;
        div.col {
            height: 40px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
        }
    }
}

.invoiceDateSelector {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
}


.newInvoiceGroup {
    display: grid;
    row-gap: 20px;
}

</style>
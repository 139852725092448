<template>
    <div class="invoicesGridGroup">

        <ModalSimple v-model="newInvoiceModal" @confirm="createInvoice" :loading="isLoading(['createInvoice', 'InvoiceGetTotal'])" confirm="Create" title="Create invoice" size="large">
            <Create v-model="newInvoice" v-if="newInvoiceModal" :errors="errors"/>
        </ModalSimple>


        <ModalSimple v-model="viewInvoice" @confirm="updateInvoice" @close="getInvoices" :loading="isLoading(['updateInvoice', 'getInvoiceDetails', 'HandleCancelInvoice'])" confirm="Save changes" :title="`Invoice details #${selectedInvoice ? selectedInvoice.invoiceNumber : ''}`" size="large">
            <View v-model="selectedInvoice" v-if="selectedInvoice" :key="selectedInvoice._id" :errors="errors" @update="viewInvoiceDetails(selectedInvoice)"/>
        </ModalSimple>

        <ModalSimple :loading="isLoading(['getCarriersHistory'])" v-model="viewCarriersInvoicesModal" @confirm="() => { viewCarriersInvoicesModal = false; }" confirm="Got it" title="Invoices by carriers" size="large">
            <CarrierHistory v-model="carriersHistory"/>
        </ModalSimple>

        <ModalSimple v-model="shareInvoiceData.show" @confirm="handleShareInvoice" confirm="Share" :loading="isLoading(['shareInvoice'])" title="Share invoice">
            <Input name="E-mail" placeholder="E-mail address" :error="shareInvoiceData.errors.email" v-model="shareInvoiceData.email" @keyup.enter="handleShareInvoice"/>
        </ModalSimple>

        <ModalSimple v-model="invoicingSettings.modal" @confirm="handleInvoicingSettings" confirm="Save changes" :loading="isLoading(['getInvoicingSettings', 'saveInvoicingSettings'])" title="Settings" size="large">
            <Invoicing v-model="invoicingSettings.item.details"/>
        </ModalSimple>

        <PageHeader title="Invoices">
            <div class="actionBar">
                <button class="default medium round _icon" @click="viewCarriersInvoicesModal = true">History <i class="fas fa-file-invoice-dollar"></i></button>
                <button class="default medium round _icon" @click="newInvoiceModal = true">Create invoice <i class="fas fa-file-invoice-dollar"></i></button>
                <button class="default medium round _icon" @click="invoicingSettings.modal = true"><i class="fa-solid fa-gear"></i></button>
            </div>
        </PageHeader>
       
        <div class="wrapper statsWrapperGroup">
            
            <div class="statsBlock" v-if="invoices.stats">
                <h4>Overall</h4>
                <div class="stats">
                    <div class="paidStatus">${{ priceConverter(invoices.stats.paid) }} Paid</div>
                    <div class="unpaidStatus">
                        <div>${{ priceConverter(invoices.stats.due) }} Unpaid</div>
                        <div class="subtle">${{ priceConverter(invoices.stats.overdue) }} Overdue</div>
                    </div>
                    <div class="statsGroupHolder">
                        <div class="bar paid" v-if="invoices.stats.paid > 0" :style="`width: ${ (invoices.stats.paid * 100) / invoices.stats.total }%`"></div>
                        <div class="bar pending" v-if="invoices.stats.due > 0" :style="`width: ${ (invoices.stats.due * 100) / invoices.stats.total }%`"></div>
                        <div class="bar overdue" v-if="invoices.stats.overdue > 0" :style="`width: ${ (invoices.stats.overdue * 100) / invoices.stats.total }%`"></div>
                    </div>
                </div>
            </div>
        </div>


        <div class="wrapper invoiceList">

            <div class="listHeader">
                <h2>Invoices • {{ invoices.total }}</h2>
                <div class="sortOptions">

                    <div class="dateRangeSelection" v-tooltip="'Show invoices by date'">
                        <button class="option" :class="{ 'active' : settings.dateType == 'all' }" @click="settings.dateType = 'all'">All invoices</button>
                        <button class="option" :class="{ 'active' : settings.dateType == 'range' }" @click="settings.dateType = 'range'">
                            <DateRangePicker2 v-model:from="settings.dateStart" v-model:to="settings.dateEnd"/>
                        </button>
                    </div>
                    
                    <ObjDropDown v-tooltip="'Show invoices by status'" class="statusSelection" :options="statuses" v-model="settings.status"/>
                    <CarrierSelector v-tooltip="'Show invoices by carrier'" v-model="settings.carrier"/>
                </div>
            </div>

            <Table class="invoicesTable" :cols="['', 'Date', 'Carrier', 'Periods', 'Total', 'Amount Due', 'Status', 'Edit', '', '', '', '']" v-if="invoices.overdue && invoices.overdue.length > 0">
                <div class="row" :key="key" v-for="(item, key) of invoices.overdue">
                    <div v-tooltip="`${item.views || 0} views`">
                        <span v-if="item.shared"><i class="fa-solid fa-check-double"></i></span>
                        <span v-else><i class="fa-solid fa-check"></i></span>
                    </div>
                    <div>{{ getDateFormat(item.createdOn) }}</div>
                    <div class="textNoWrap">{{ carriersObj[item.carrier] ? carriersObj[item.carrier].name : 'Unknown' }}</div>
                    <div v-tooltip="`` + item.periods.join(', ')">
                        <div class="textNoWrap" >{{ item.periods.join(", ") }}</div>
                    </div>
                    <div>${{ priceConverter(item.total.toFixed(2)) }}</div>
                    <div>${{ priceConverter(item.amountDue.toFixed(2)) }}</div>
                    <div><InvoiceStatus :item="item"/></div>
                    <div><button class="default small reverse" @click="viewInvoiceDetails(item)">Edit</button></div>
                    <div><button class="default small reverse" @click="openInvoiceURL(item)">View</button></div>
                    <div><a href="" @click.prevent="shareInvoice(item)" class="downloadBtn"><i class="fa-solid fa-paper-plane"></i></a></div>
                    <div><a :href="apiURL + '/invoice/DownloadPDF/' + item._id" target="_blank" class="downloadBtn"><i class="fa-solid fa-download"></i></a></div>
                    <div><button class="downloadBtn delete" @click="deleteItem(item, key)"><i class="fas fa-trash-alt"></i></button></div>
                </div>
            </Table>

            <Table class="invoicesTable" :cols="['', 'Date', 'Carrier', 'Periods', 'Total', 'Amount Due', 'Status', 'Edit', '', '', '', '']" v-if="invoices.list">
                <div class="row" :key="key" v-for="(item, key) of invoices.list">
                    <div v-tooltip="`${item.views || 0} views`">
                        <span v-if="item.shared"><i class="fa-solid fa-check-double"></i></span>
                        <span v-else><i class="fa-solid fa-check"></i></span>
                    </div>
                    <div>{{ getDateFormat(item.createdOn) }}</div>
                    <div class="textNoWrap">{{ carriersObj[item.carrier] ? carriersObj[item.carrier].name : 'Unknown' }}</div>
                    <div v-tooltip="`` + item.periods.join(', ')">
                        <div class="textNoWrap" >{{ item.periods.join(", ") }}</div>
                    </div>
                    <div>${{ priceConverter(item.total.toFixed(2)) }}</div>
                    <div>${{ priceConverter(item.amountDue.toFixed(2)) }}</div>
                    <div><InvoiceStatus :item="item"/></div>
                    <div><button class="default small reverse" @click="viewInvoiceDetails(item)">Edit</button></div>
                    <div><button class="default small reverse" @click="openInvoiceURL(item)">View</button></div>
                    <div><a href="" @click.prevent="shareInvoice(item)" class="downloadBtn"><i class="fa-solid fa-paper-plane"></i></a></div>
                    <div><a :href="apiURL + '/invoice/DownloadPDF/' + item._id" target="_blank" class="downloadBtn"><i class="fa-solid fa-download"></i></a></div>
                    <div><button class="downloadBtn delete" @click="deleteItem(item, key)"><i class="fas fa-trash-alt"></i></button></div>
                </div>
            </Table>
            
            <div class="pagination" v-if="invoices.list && invoices.pages && invoices.pages > 1">
                <button class="arrow" :disabled="settings.page <= 1" @click="nextPage(-1)"><i class="fa-solid fa-arrow-left"></i></button>
                <div class="pageNum">{{ settings.page }}</div>
                <button class="arrow" @click="nextPage(1)" :disabled="settings.page >= invoices.pages"><i class="fa-solid fa-arrow-right"></i></button>
            </div>

        </div>

    </div>
</template>

<script>

import moment from 'moment'
import { mapGetters } from 'vuex'
import MultiWeekSelector from '../../components/MultiWeekSelector.vue'
import WeekPicker from '../../components/WeekPicker.vue'
import Create from '../../components/invoices/Create.vue'
import View from '../../components/invoices/View.vue'
import InvoiceStatus from '../../components/invoices/InvoiceStatus.vue'
import CarrierHistory from '../../components/invoices/CarrierHistory.vue'
import CarrierSelector from '../../components/dispatch/CarrierSelector.vue'
import DateRangePicker2 from '../../components/DateRangePicker2.vue'
import Invoicing from '../../components/settings/Invoicing.vue'

    export default {
        components: {
            MultiWeekSelector,
            WeekPicker,
            Create,
            View,
            InvoiceStatus,
            CarrierHistory,
            CarrierSelector,
            Invoicing,
            DateRangePicker2
        },
        data() {
            return {
                settings: {
                    page: 1,
                    carrier: '',
                    status: 'all',
                    dateStart: moment.utc().startOf('day').subtract(365, 'days').format("MM/DD/YYYY"),
                    dateType: 'all',
                },
                statuses: {
                    "all": { text: 'All statuses', value: '' },
                    "paid": { text: 'Paid', value: '' },
                    "due": { text: 'Due', value: '' },
                    "overdue": { text: 'Overdue', value: '' },
                    "cancelled": { text: 'Cancelled', value: ''}
                },
                invoices: {},
                newInvoiceModal: false,
                newInvoice: null,
                errors: {},
                viewInvoice: false,
                selectedInvoice: null,
                viewCarriersInvoicesModal: false,
                invoicingSettings: {
                    modal: false,
                    item: {},
                },
                filters: {
                    carrier: ''
                },
                shareInvoiceData: {
                    errors: {},
                    invoice: {},
                    email: '',
                    show: false
                },
                carriersHistory: {}
            }
        },
        computed: {
            ...mapGetters(['user', 'carriers']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            carriersObj() {
                let carriers = {};
                this.carriers.map(item => {
                    carriers[item._id] = carriers[item._id] || item;
                });
                return carriers;
            },
            invoiceList() {
                return this.invoices.list;
            },
            invoicesByCarrier() {
                let id = this.filters.carrier;
                let list = this.invoiceList || [];
                return list.filter(item => item.carrier.includes(id));
            },
            overDueInvoices() {
                return this.invoicesByCarrier.filter(item => {

                    if(item.status == 'paid') return false;

                    let start = moment().startOf('day');
                    let end = moment(item.dueDate, "MM/DD/YYYY");

                    var duration = moment.duration(end.diff(start));
                    let days = Number(duration.asDays());
                    if(days < 0) return true;
                });
            },
            dueInvoices() {
                return this.invoicesByCarrier.filter(item => {

                    if(item.status == 'paid') return false;

                    let start = moment().startOf('day');
                    let end = moment(item.dueDate, "MM/DD/YYYY");

                    var duration = moment.duration(end.diff(start));
                    let days = Number(duration.asDays());
                    if(days >= 0 && item.status == 'pending') return true;
                });
            },
            paidInvoices() {
                return this.invoicesByCarrier.filter(item => {
                    if(item.status == 'paid') return true;
                });
            }
        },
        mounted() {
            this.getInvoices();
        },
        methods: {
            getCarriersHistory() {
                this.ajax('getCarriersHistory', {
                    url: '/invoices/CarriersHistory',
                    method: 'GET',
                }, (err, body) => {
                    if(err) {
                        alert(body.message || "Something went wrong. Please, try again!");
                        return;
                    }
                    this.carriersHistory = body;
                });
            },
            shareInvoice(item) {
                this.shareInvoiceData.invoice = item;
                this.shareInvoiceData.show = true;
                let carrier = this.carriersObj[item.carrier];
                if(carrier && carrier.invoiceEmail) {
                    this.shareInvoiceData.email = carrier.invoiceEmail;
                } else {
                    this.shareInvoiceData.email = '';
                }
                
            },
            handleShareInvoice() {
                this.shareInvoiceData.errors = {};

                this.ajax('shareInvoice', {
                    url: '/invoices/share',
                    method: 'POST',
                    data: {
                        _id: this.shareInvoiceData.invoice._id,
                        url: this.shareInvoiceData.invoice.invoiceUrl,
                        email: this.shareInvoiceData.email
                    }
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.shareInvoiceData.errors = body.errors;
                        else alert(body.message || "Something went wrong. Please, try again!");
                        return;
                    }
                    this.shareInvoiceData.errors = {};
                    this.shareInvoiceData.show = false;
                    this.shareInvoiceData.email = '';
                });
            },
            openInvoiceURL(item) {
                window.open(this.getInvoiceUrl(item._id), '_blank').focus();
            },
            getInvoiceUrl(id) {
                return window.location.origin + "/invoice/" + id;
            },
            async deleteItem(item, key) {
                let isConfirmed = confirm("Are you sure?");
                if(!isConfirmed) return;

                await this.$axios.delete(`/invoices/${item._id}`)
                .then(data => {

                });
                this.getInvoices();
            },
            viewInvoiceDetails(item) {
                this.selectedInvoice = JSON.parse(JSON.stringify(item));

                this.ajax('getInvoiceDetails', {
                    url: `/invoices/${item._id}`,
                    method: 'GET',
                }, (err, body) => {
                    if(err) return;
                    this.selectedInvoice = body;
                });

                this.viewInvoice = true;
            },
            getDateFormat(date) {
                date = new Date(date);
                return moment(date).format("D MMM, YYYY");
            },
            updateInvoice() {
                this.errors = {};
                this.ajax('updateInvoice', {
                    url: `/invoices`,
                    method: 'PUT',
                    data: this.selectedInvoice
                }, (err, body) => {
                    if(err) {
                        if(body.errors) { this.errors = body.errors; } 
                        else if(body.message) { alert(body.message); } 
                        else { alert("Something went wrong. Please, try again!"); }
                        return;
                    };

                    let item = body.body;
                    this.getInvoices();
                    this.viewInvoice = false;
                });
            },
            createInvoice() {
                this.errors = {};
                this.ajax('createInvoice', {
                    url: `/invoices`,
                    method: 'POST',
                    data: this.newInvoice
                }, (err, body) => {
                    if(err) {
                        if(body.errors) { this.errors = body.errors; } 
                        else if(body.message) { alert(body.message); } 
                        else { alert("Something went wrong. Please, try again!"); }
                        return;
                    };
                    this.getInvoices();
                    this.newInvoiceModal = false;
                });
            },
            getInvoices() {
                let params = new URLSearchParams(this.settings).toString();
                
                this.ajax('getInvoices', {
                    url: `/invoices?${params}`,
                    method: 'GET',
                }, (err, body) => {
                    if(err) return;
                    this.invoices = body;
                });
            },
            getInvoicingSettings() {
                this.ajax('getInvoicingSettings', {
                    url: `/settings/Invoicing`,
                    method: 'GET',
                }, (err, body) => {
                    if(err) return;
                    console.log(body);
                    this.invoicingSettings.item = body;
                });
            },
            handleInvoicingSettings() {
                this.ajax('saveInvoicingSettings', {
                    method: 'POST',
                    url: '/settings',
                    data: this.invoicingSettings.item
                }, (err, body) => {
                    
                });
            },
            nextPage(num) {
                this.settings.page += num;
            }
        },
        watch: {
            "invoicingSettings.modal"() {
                if(this.invoicingSettings.modal) {
                    this.getInvoicingSettings();
                }
            },
            settings: {
                deep: true,
                handler() {
                    this.getInvoices();
                }
            },
            viewCarriersInvoicesModal() {
                if(this.viewCarriersInvoicesModal) this.getCarriersHistory();
            }
        }
    }
</script>

<style lang="scss">
.invoicesTable {
    .row {
       grid-template-columns: 14px 100px 150px minmax(0, 1fr) 120px 120px 200px 75px 75px 34px 34px 34px !important;
    }
}

</style>


<style lang="scss" scoped>

.dateRangeSelection {
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    display: grid;
    height: 40px;
    grid-template-columns: auto auto;
    .option {
        height: 100%;
        padding: 0 20px;
        border: 0;
        background: transparent;
        font-size: 14px;
        border-right: 1px solid $borderColor;
        &:last-child {
            border: 0;
        }
        &.active {
            font-weight: bold;
            color: $buttonColor;
        }
    }
}

.statusSelection {
    box-shadow: $boxShadow;
}

.pagination {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 44px 50px 44px;
    column-gap: 10px;
    .pageNum {
        text-align: center;
        font-size: 20px;
    }
    .arrow {
        width: 44px;
        height: 44px;
        border: 1px solid $borderColor;
        background: transparent;
        border-radius: 50%;
        font-size: 16px;
        color: $text;
        transition: ease 0.3s;
        &:hover {
            background: $buttonColor;
            color: #fff;
        }
        &:disabled {
            background: $borderColor;
            color: inherit;
            cursor: not-allowed;
        }
    }
}

.statsWrapperGroup {
    display: grid;
}

.invoiceList {
    display: grid;
    row-gap: 20px;
    position: relative;
    .invoiceLoaderHolder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.5);
        z-index: 2;
        .loader {
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            left: 0;
            height: 100%;
            max-height: 50vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &.loading {
        min-height: 400px;
    }
}

.statsBlock {
    display: grid;
    row-gap: 10px;
}

.listHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sortOptions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
    }
}

.invoicesGridGroup {
    display: grid;
    row-gap: 20px;
}

.stats {
    position: relative;
    height: 75px;
    .paidStatus {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: 500;
    }
    .unpaidStatus {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .subtle {
            font-size: 13px;
            font-weight: 400;
        }
    }
    .statsGroupHolder {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: flex-end;
    }
    .bar {
        height: 30px;
        width: 100%;
        border-radius: 5px 20px 8px 20px;
        box-sizing: content-box;
        margin-left: 10px;
        bottom: 0;
        transition: ease 0.3s;
        border-bottom-width: 0;
        border-bottom-style: solid;
        min-width: 10px;
        &:first-child {
            margin: 0;
        }
        &.paid {
            background: $success;
            border-bottom-color: #229954;
        }
        &.pending {
            background: #BFC9CA;
            border-bottom-color: #5F6A6A;
        }
        &.overdue {
            background: #DC7633;
            border-bottom-color: #873600;
        }
        &:hover {
            border-bottom-width: 5px;
        }
    }
}

.invoiceTotals {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
}

.actionBar {
    display: flex;
    align-items: center;
    button {
        margin-left: 20px;
    }
}

.deleteBtn {
    color: $error;
    background: transparent;
    border: 0;
    width: 40px;
    height: 40px;
}

.invoicesTable {
    .row {
        display: grid;
        grid-template-columns: 14px 100px 150px minmax(0, 1fr) 120px 120px 200px 75px 75px 34px 34px 34px !important;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;
        button.default {
            width: 100%;
        }

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
    }
}

.downloadBtn {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-bottom: 3px solid $disabledButtonColor;
    background: $buttonColor2;
    font-size: 10px;
    border-radius: $borderRadius;
    padding: 0;
    &.delete {
        color: $error;
    }
}
</style>
<template>
    <div class="invoiceDetails">

        <div class="results">   

            <div class="itemsGrid">
                <div class="invoicesItems">
                    <div class="item header">
                        <div class="col">Service</div>
                        <div class="col">Description</div>
                        <div class="col">QTY</div>
                        <div class="col">Rate</div>
                        <div class="col">Amount</div>
                    </div>
                    <Item :item="item" @remove="removeItem(key)" :key="key" v-for="(item, key) of newInvoice.items"/>
                </div>
                <div>
                    <button class="default small round" @click="addLastRow">Add row</button>
                </div>
            </div>

            <div class="totalsHolder">
                
                <div class="totalRow">
                    <div class="name">Subtotal</div>
                    <div class="value"><strong>${{ priceConverter(subtotal) }}</strong></div>
                </div>

                <div class="totalRow">
                    <div class="name">Total</div>
                    <div class="value"><strong>${{ priceConverter(subtotal) }}</strong></div>
                </div>

                <div class="totalRow" v-if="newInvoice._id">
                    <div class="name">Amount paid</div>
                    <div class="value paidRow">
                        <strong>${{ priceConverter(newInvoice.amountPaid.toFixed(2)) }}</strong>
                        <a href="" @click.prevent="addInvoicePaidItemModal"><i class="fa-solid fa-plus"></i></a>
                    </div>
                </div>

                <div class="totalRow" v-if="newInvoice.status == 'pending'">
                    <div class="name">Balance due</div>
                    <div class="value"><strong>${{ priceConverter(invoiceTotal) }}</strong></div>
                </div>

            </div>

        </div>


        <div class="input" v-if="newInvoice._id && newInvoice.paidItems && newInvoice.paidItems.length > 0">
            <div class="_header">
                <label><h4>Paid items</h4></label>
            </div>
            <div class="listOfPaidItems">
                <div class="paidItem header">
                    <div class="date">Date</div>
                    <div class="amount">Amount</div>
                </div>
                <div class="paidItem" :key="key" v-for="(item, key) of newInvoice.paidItems">
                    <div class="date">{{ item.date }}</div>
                    <div class="amount">${{ priceConverter(item.amountPaid.toFixed(2)) }}</div>
                    <div class="button"><button class="default small reverse" @click="addInvoicePaidItemModal(item)"><i class="fa-solid fa-pencil"></i></button></div>
                    <div class="button"><button class="default small reverse delete" @click="removeInvoicePaidItem(item._id)"><i class="fa-solid fa-trash-can"></i></button></div>
                </div>
            </div>
        </div>

        <div class="input">
            <div class="_header">
                <label><h4>Message on invoice</h4></label>
            </div>
            <textarea class="textArea" placeholder="Thiw will show up on the invoice." v-model="newInvoice.message"></textarea>
        </div>


        <div class="itemsGrid">
            <div class="_header">
                <label><h4>Security</h4></label>
            </div>

            <div class="contentHolder">
                <div class="row">
                    <div class="name">Enable password</div>
                    <div class="value"><CheckSwitch v-model="newInvoice.enablePassword"/></div>
                </div>
                <div class="row" v-if="newInvoice.enablePassword">
                    <div class="name">Invoice password</div>
                    <div class="value"><input type="text" class="invisibleInput textRight" :class="{ 'error' : errors.carrier }" placeholder="Invoice password" v-model="newInvoice.password"/></div>
                </div>
            </div>

        </div>
        
        

    </div>
</template>

<script>
import Item from './Item.vue'
import AddInvoicePaidItem from './AddInvoicePaidItem.vue'

    export default {
        components: {
            Item
        },
        props: ['modelValue', 'errors'],
        data() {
            return {
                newInvoice: this.modelValue
            }
        },
        computed: {
            items() {
                return this.newInvoice.items ? this.newInvoice.items : [];
            },
            subtotal() {
                let total = 0;
                for(const item of this.items) {
                    total += Number(item.amount);
                }
                return total.toFixed(2);
            },
            invoiceTotal() {
                let total = Number(this.subtotal);

                if(this.newInvoice.amountPaid) {
                    var regex = /^(?:[1-9][0-9]*|0)((?:[.]\d{1,2})?)$/;
                    if(regex.test(this.newInvoice.amountPaid)) {
                        total -= this.newInvoice.amountPaid;
                    }  
                }

                return total.toFixed(2);
            }
        },
        methods: {
            addInvoicePaidItemModal(item = {}) {
                if(!this.newInvoice._id) return;
                item.id = this.newInvoice._id;

                let invoice = {
                    ...item,
                    id: this.newInvoice._id
                }

                let options = {
                    item: invoice,
                    amountDue: this.newInvoice.amountDue || null 
                }

                this.$showModalSimple(AddInvoicePaidItem, 'Add paid item', options, (data) => {
                    this.$emit('update');
                });
            },
            removeInvoicePaidItem(id) {
                this.ajax('HandleDeletePaidItem', {
                    url: '/invoices/RemoveInvoicePaidItem',
                    method: 'POST',
                    data: {
                        _id: id
                    }
                }, (err, body) => {
                    this.$emit('update');
                });
            },
            removeItem(key) {
                this.newInvoice.items.splice(key, 1);
            },
            addLastRow() {

                this.newInvoice.items.push({
                    service: '',
                    description: '',
                    quantity: '',
                    rate: '',
                    amount: ''
                });
                
            },
        },
        watch: {
            modelValue: {
                deep: true,
                handler() {
                    this.newInvoice = this.modelValue;
                }
            },
            newInvoice: {
                deep: true,
                handler() {
                    this.$emit("update:modelValue", this.newInvoice);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.listOfPaidItems {
    padding: 20px;
    background: $bgSecondary;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    .paidItem {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr)) 34px 34px;
        height: 34px;
        padding: 0 0 0 15px;
        align-items: center;
        align-content: center;
        &.header {
            font-weight: 600;
            background: $buttonColor2;
            text-transform: uppercase;
            font-size: 12px;
            border-radius: 5px;
            margin-bottom: 3px;
        }
        .button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button {
            width: 24px;
            height: 24px;
            padding: 0;
            font-size: 10px;
            &.delete {
                color: $error;
            }
        }
    }
}

.paidRow {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
    align-items: center;
    justify-items: start;
    justify-content: start;
}

.invoiceDetails {
    display: grid;
    row-gap: 20px;
}

.textArea {
    padding: 20px;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    resize: none;
    height: 150px !important;
    background: $bgSecondary;
}

.itemsGrid {
    display: grid;
    row-gap: 10px;
}

.invoicesItems {
    display: grid;
    row-gap: 10px;
    background: $bgSecondary;
    padding: 20px;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    .item {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr)) 40px;
        column-gap: 5px;
        &.header {
            background: $bg;
        }
        div.col {
            height: 40px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
        }
    }
}


.results {
    display: grid;
    row-gap: 20px;
    .total {
        text-align: right;
        font-size: 20px;
    }
}

.totalsHolder {
    margin-left: auto;
    display: grid;
    row-gap: 10px;
}

.totalRow {
    display: grid;
    grid-template-columns: 100px 150px;
    align-items: center;
    align-content: center;
    column-gap: 10px;
    .name {
        font-size: 14px;
    }
    .value {
        input {
            height: 34px;
            width: 100%;
            border: 1px solid $borderColor;
            padding: 0 10px;
            border-radius: $borderRadius;
        }
    }
}

</style>
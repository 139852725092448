<template>
    <div>

        <div class="chartGroup">

            <div class="chartHolder">
                <div class="histoChart">

                    <div class="histo header">
                        <div class="details">
                            <div class="name weeksOffsetButtons textNoWrap">
                                <button class="default small reverse" @click="weeksOffset--"><i class="fas fa-caret-left"></i></button>
                                <button class="default small reverse" @click="weeksOffset++"><i class="fas fa-caret-right"></i></button>
                            </div>
                        </div>
                        <div class="weeksStats">
                            <div class="week" :key="key" v-for="(week, key) of lastWeeks">
                                <div class="weekHolder">{{ week.weekName }}</div>
                            </div>
                        </div>
                    </div>
                    


                    <div class="histo" :key="key" v-for="(item, key) in carriers">
                        <div class="details">
                            <div class="name textNoWrap">{{ item.name }}</div>
                        </div>
                        <div class="weeksStats">
                            <div class="week" :key="key" v-for="(week, key) of lastWeeks">
                                <div class="weekHolder" v-tooltip="`${week.weekName}`">
                                    <button v-if="carrierWeekIncluded(item._id, week.yearWeek)" class="active"><i class="fas fa-check"></i></button>
                                    <button v-else-if="carrierWeekDue(item._id, week.yearWeek)" class="pending"><i class="fas fa-spinner"></i></button>
                                    <button v-else></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

    export default {
        props: ['modelValue'],
        data() {
            return {
                numberOfWeeks: 7,
                weeksOffset: 0
            }
        },
        computed: {
            ...mapGetters(['carriers']),
            stats() {
                let stats = this.modelValue || [];
                return stats;
            },
            carriersObj() {
                let carriers = {};
                this.carriers.map(item => {
                    carriers[item._id] = carriers[item._id] || item;
                });
                return carriers;
            },
            lastWeeks() {
                let date = moment().startOf('isoWeek').add(this.weeksOffset, 'weeks');
                let weeks = [];
                for(let i = 1; i <= this.numberOfWeeks; i++) {
                    weeks.push({
                        weekName: "W" + date.isoWeek(),
                        yearWeek: date.year() + "W" + date.isoWeek()
                    });

                    date.subtract(1, 'weeks');
                }
                return weeks.reverse();
            }
        },
        methods: {
            carrierWeekIncluded(carrier, week) {
                if(!this.stats.paid) return false;
                for(const item of this.stats.paid) {
                    if(item._id == carrier && item.periods.includes(week)) return true;
                }
                return false;
            },
            carrierWeekDue(carrier, week) {
                if(!this.stats.pending) return false;
                for(const item of this.stats.pending) {
                    if(item._id == carrier && item.periods.includes(week)) return true;
                }
                return false;
            }
        },
    }
</script>

<style lang="scss" scoped>

.chartGroup {
    background: $bgSecondary;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
    overflow: hidden;
    .title {
        border-bottom: 1px solid $borderColor;
        height: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .name {
            font-weight: bold;
            font-size: 14px;
            text-transform: uppercase;
        }
    }
}

.weeksOffsetButtons {
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
}

.weeksStats {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    column-gap: 10px;
    .week {
        width: 100%;
        padding-top: 100%;
        position: relative;
        .weekHolder {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: bold;
            button {
                width: 50%;
                height: 50%;
                border-radius: 50%;
                border: 1px solid $borderColor;
                background: transparent;
                font-size: 12px;
                padding: 0;
                &.active {
                    background: $success;
                    color: #fff;
                }
                &.pending {
                    border: 0;
                    font-size: 16px;
                    animation-name: spin;
                    animation-duration: 2000ms;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear; 
                }
            }
        }
    }
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.chartHolder {
    background: $bg;
}

.histoChart {
    .histo {
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 200px auto;
        column-gap: 20px;
        position: relative;
        padding: 10px 20px;
        &:nth-child(2n) {
            background: $bgSecondary;
        }
        .barStats {
            display: grid;
            row-gap: 5px;
        }
        &.header {
            border-bottom: 1px solid $borderColor;
            height: 54px;
            padding: 0 20px;
            background: $bgSecondary;
        }
        .bar {
            background: $success;
            width: 0%;
            height: 10px;
            transition: 1s ease;
            transition-delay: 0.5s;
            border-radius: 3px;
            &.loadsNum {
                background: $buttonColor;
                // background: #F7DC6F;
            }
        }
        .details {
            display: grid;
            row-gap: 5px;
            border-right: 1px solid $borderColor;
            .name {
                font-weight: bold;
                padding: 0 20px 0 0;
            }
        }
    }
}
</style>
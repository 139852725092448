<template>
    <div style="display: grid; row-gap: 20px; width: 100%;">

        <div class="settingItem">
            <h3>Invoicing domain</h3>
            <div style="display: grid; row-gap: 10px">
                <Input v-model="item.invoiceURL" name="Domain name" placeholder="https://example.com/"/>
            </div>
        </div>

        <div class="settingItem">
            <h3>Business details</h3>
            <div style="display: grid; row-gap: 10px">
                <Input v-model="item.businessName" name="Name"/>
                <Input v-model="item.businessAddress1" name="Address line 1"/>
                <Input v-model="item.businessAddress2" name="Address line 2"/>
                <Input v-model="item.businessPhone" name="Contact phone"/>
                <Input v-model="item.businessEmail" name="Contact email address"/>
            </div>
        </div>


        <div class="settingItem">
            <h3>SMTP Settings</h3>
            <p>Set SMTP server to send invoices to carriers using email</p>
            <div style="display: grid; row-gap: 10px">
                <div class="col-2">
                    <Input v-model="item.smtpHost" name="SMTP Host"/>
                    <Input v-model="item.smtpPort" name="SMTP Port"/>
                </div>
                <div class="col-2">
                    <Input v-model="item.smtpUsername" name="SMTP Username"/>
                    <Input v-model="item.smtpPassword" name="SMTP Password"/>
                </div>
            </div>
        </div>

        <div class="settingItem">
            <h3>Email Additional Text</h3>
            <div style="display: grid; row-gap: 10px">
                <textarea id="editor"/>
            </div>
        </div>


    </div>
</template>

<script>

import 'jodit/build/jodit.min.css'
const { Jodit } = require("jodit/build/jodit");

    export default {
        props: ['modelValue'],
        data() {
            return {
                editor: null,
                item: this.modelValue || {}
            }
        },
        mounted() {
            this.editor = new Jodit('#editor');
            this.editor.value = this.item.emailAdditionalText;
            this.editor.e.on('change', () => {
                this.item.emailAdditionalText = this.editor.getEditorValue();
            });
        },
        watch: {
            modelValue() {
                this.item = this.modelValue || {};
                this.editor.value = this.item.emailAdditionalText;
            }
        }
    }
</script>

<style lang="scss" scoped>

.settingItem {
    display: grid;
    row-gap: 10px;
    p {
        opacity: 0.7;
    }
}

.col-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 10px;
}
</style>
<template>
    <div class="item">
        <input type="text" v-model="item.service" placeholder="Service">
        <input type="text" v-model="item.description" placeholder="Description">
        <input type="text" v-model="item.quantity" placeholder="Quantity">
        <input type="text" v-model="item.rate" placeholder="Rate">
        <input type="text" :value="total" placeholder="Amount" disabled>
        <button class="deleteBtn" @click="$emit('remove')"><i class="fas fa-trash-alt"></i></button>
    </div>
</template>

<script>
    export default {
        props: ['item'],
        computed: {
            total() {
                return (Number(this.item.quantity) * Number(this.item.rate)).toFixed(2);
            }
        },
        watch: {
            total() {
                this.item.amount = this.total;
            }
        }
    }
</script>

<style lang="scss" scoped>
.item {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr)) 40px;
    column-gap: 5px;
    input {
        height: 40px;
        padding: 0 10px;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
    }
    .deleteBtn {
        width: 40px;
        height: 40px;
        border: 0;
        background: transparent;
        color: $error;
        font-size: 12px;
    }
}
</style>
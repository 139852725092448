<template>
    <div>
        <button v-if="item.status == 'paid'" class="status paid">Paid</button>
        <button v-else-if="item.status == 'cancelled'" class="status cancelled">Cancelled</button>
        <button v-else-if="item.status == 'pending'" class="status" :class="getStatusDetails.class">
            {{ getStatusDetails.text }}
        </button>
    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['item'],
        computed: {
            getStatusDetails() {
                let status = {
                    class: '',
                    text: 'Pending'
                }

                let start = moment().startOf('day');
                let end = moment(this.item.dueDate, "MM/DD/YYYY");

                var duration = moment.duration(end.diff(start));
                let days = Number(duration.asDays().toFixed(0));

                if(days >= 1) {
                    return {
                        class: 'due',
                        text: `Due in ${days} ${ days == 1 ? 'day' : 'days' }`
                    }
                } else if(days == 0) {
                    return {
                        class: 'today',
                        text: `Due today`
                    }
                } else {
                    days = days * (-1);
                    return {
                        class: 'overdue',
                        text: `Overdue by ${days} ${ days == 1 ? 'day' : 'days' }`
                    }
                }

                

                return status;
            }
        },
    }
</script>

<style lang="scss" scoped>
.status {
    height: 34px;
    padding: 0 20px;
    border: 0;
    background: $buttonColor2;
    border-radius: 17px;
    &.paid {
        color: #270;
        background-color: #DFF2BF;
    }
    &.due {
        color: #059;
        background-color: #BEF;
    }
    &.today {
        color: #9F6000;
        background-color: #FEEFB3;    
    }
    &.cancelled {
        background: $bgSecondary;
    }
    &.overdue {
        color: #D8000C;
        background-color: #FFBABA;
    }
}
</style>
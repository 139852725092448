<template>
    <div class="newInvoiceGroup">

        

        <div class="input">
            <div class="_header">
                <label>Terms</label>
            </div>
            <select v-model="terms">
                <option :value="item.value" :key="key" v-for="(item, key) of paymentTerms">{{ item.name }}</option>
            </select>
        </div>


        <div class="invoiceDateSelector">

            <div class="input">
                <div class="_header">
                    <label>Invoice date</label>
                    <span class="_error">{{ errors.date }}</span>
                </div>
                <DatePicker2 v-model="newInvoice.date" style="justify-content: flex-start"/>
            </div>

            <div class="input">
                <div class="_header">
                    <label>Due date</label>
                    <span class="_error">{{ errors.dueDate }}</span>
                </div>
                <DatePicker2 v-model="newInvoice.dueDate" style="justify-content: flex-start"/>
            </div>
            
        </div>

        <div class="input">
            <div class="_header">
                <label>Carrier</label>
                <span class="_error">{{ errors.carrier }}</span>
            </div>
            
            <select v-model="newInvoice.carrier">
                <option :value="null">-- Select carrier --</option>
                <option :key="key" v-for="(item, key) of carriers" :value="item._id">{{ item.name }}</option>
            </select>
        </div>

        <div class="input">
            <div class="_header">
                <label>Periods</label>
                <span class="_error">{{ errors.periods }}</span>
            </div>
            <MultiWeekSelector :match="paidWeeks" v-model="newInvoice.periods"/>
        </div>

        <Details v-model="newInvoice" :errors="errors"/>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

import Item from './Item.vue'
import MultiWeekSelector from '../../components/MultiWeekSelector.vue'
import WeekPicker from '../../components/WeekPicker.vue'
import Details from './Details.vue'


    export default {
        props: ['modelValue', 'errors'],
        components: {
            Item,
            MultiWeekSelector,
            WeekPicker,
            Details
        },
        data() {
            return {
                terms: 30,
                paymentTerms: [
                    { name: '3 days', value: 3 },
                    { name: '5 days', value: 5 },
                    { name: '30 days', value: 30 }
                ],
                newInvoice: {
                    periods: null,
                    carrier: null,
                    date: null,
                    items: [{ service: '', description: '', quantity: '', rate: '', amount: '' }],
                    dueDate: moment().add(30, 'days').format("MM/DD/YYYY")
                },
                paidWeeks: [],
                statuses: [
                    { name: 'Awaiting payment', value: 'pending' },
                    { name: 'Paid', value: 'paid' },
                    { name: 'Cancelled', value: 'cancelled' }
                ]
            }
        },
        computed: {
            ...mapGetters(['carriers']),
            invoicePeriod() {
                return this.newInvoice ? this.newInvoice.periods : [];
            },
            invoiceCarrier() {
                return this.newInvoice ? this.newInvoice.carrier : '';
            }
        },
        mounted() {
            
        },
        methods: {
            changeStatusTo(status) {
                this.newInvoice.status = status;
            },
            getPaidWeeks() {
                if(!this.newInvoice.carrier) return;
                this.$axios.get(`/invoices/GetPaidWeeks/${this.newInvoice.carrier}`)
                .then(data => {
                    this.paidWeeks = data.data || [];
                });
            },
            getInvoiceTotal() {
                if(!this.newInvoice.periods || !this.newInvoice.carrier) return;
                this.newInvoice.items = [];
                this.ajax('InvoiceGetTotal', {
                    url: `/invoices/GetTotal`,
                    method: 'POST',
                    data: this.newInvoice
                }, (err, body) => {
                    if(err) return;
                    console.log(body);
                    this.newInvoice.items = body.items || [];
                });
                // this.$axios.post('/invoices/GetTotal', this.newInvoice)
                // .then(data => {
                //     this.newInvoice.items = data.data.items || [];
                // });
            },
        },
        watch: {
            terms() {
                console.log("changed");
                this.newInvoice.dueDate = moment().add(this.terms, 'days').format("MM/DD/YYYY");
            },
            modelValue: {
                deep: true,
                handler() {
                    this.newInvoice = this.modelValue;
                }
            },
            newInvoice: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.newInvoice);
                }
            },
            invoicePeriod: {
                deep: true,
                handler() {
                    this.getInvoiceTotal();
                }
            },
            invoiceCarrier() {
                this.getInvoiceTotal();
                this.getPaidWeeks();
            }
        }
    }
</script>

<style lang="scss" scoped>


.statusSelection {
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
}

.invoicesItems {
    display: grid;
    row-gap: 10px;
    .item {
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr)) 40px;
        column-gap: 5px;
        div.col {
            height: 40px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
        }
    }
}

.invoiceDateSelector {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
}


.newInvoiceGroup {
    display: grid;
    row-gap: 20px;
}

</style>
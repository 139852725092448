<template>
    <div class="content">
        
        <div class="formHolder">
            <div class="input">
                <div class="_header">
                    <label>Paid date</label>
                    <span class="_error">{{ errors.date }}</span>
                </div>
                <DatePickerSimple position="left" v-model="item.date"/>
            </div>
            <Input v-model="item.amountPaid" name="Amount paid" placeholder="Enter amount" :error="errors.amountPaid" validate="double"/>
        </div>
        

        <div class="actionButtons">
            <button class="default medium" :disabled="isLoading(['HandleSaveAmountPaid'])" @click="saveAmountPaid">{{ isLoading(['HandleSaveAmountPaid']) ? 'Saving...' : 'Save' }}</button>
        </div>

    </div>
</template>

<script>
import DatePickerSimple from '../DatePickerSimple.vue'

    export default {
        components: {
            DatePickerSimple
        },
        props: ['options'],
        data() {
            return {
                item: {
                    invoice: this.options && this.options.item && this.options.item.id ? this.options.item.id : null,
                    amountPaid: this.options && this.options.amountDue ? this.options.amountDue.toFixed(2) : '0',
                    ...this.options.item
                },
                errors: {}
            }
        },
        methods: {
            saveAmountPaid() {
                this.errors = {};
                this.ajax('HandleSaveAmountPaid', {
                    url: '/invoices/AddInvoicePaidItem',
                    method: 'POST',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$hideModalSimple({});
                });
            }
        },
    }
</script>

<style lang="scss" scoped>

.content {
    display: grid;
    row-gap: 20px;
}

.formHolder {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
    align-items: start;
}

.actionButtons {
    display: flex;
    justify-content: flex-end;
}
</style>